import React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import Image from "../../components/image"
import SEO from "../../components/seo"


import Hero from "../../components/hero"

const pageTitle = "Tussentijdse toets"

const TussentijdsetoetsPage = () => (
<Layout>
    <SEO title={pageTitle} />
    
    
    <Hero>
        <h1 class="title has-text-light">
            {pageTitle}
        </h1>
    </Hero> 
</Layout>
)

export default TussentijdsetoetsPage
